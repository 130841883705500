<template>
    <div>
        <el-dialog v-model="outerVisible" :title="$t('switchGoogle.name1')">
            <el-form :model="form" :rules="rules" ref='formEl'>
                <el-form-item :label="$t('switchGoogle.name2')" prop='newpassword1'>
                    <el-input v-model="form.newpassword1" type="password" :placeholder="$t('inputTit.shuru')"/>
                </el-form-item>
                <el-form-item :label="$t('switchGoogle.name3')" prop='password' >
                    <el-input v-model="form.password" type="password" :placeholder="$t('inputTit.shuru')" />
                </el-form-item>
                <el-form-item :label="$t('switchGoogle.name4')" prop='newGoogleKey'>
                    <el-input v-model="form.newGoogleKey" disabled>
                        <template #append>
                            <div @click="getKey" style="cursor:pointer">{{$t('switchGoogle.text1')}}</div>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item :label="$t('switchGoogle.name5')" prop='code' >
                    <el-input v-model="form.code" :placeholder="$t('switchGoogle.placeholder')"/>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="outerVisible = false">{{$t('button.close')}}</el-button>
                    <el-button type="primary" @click="enterFun">{{$t('button.enter')}}</el-button>
                </div>
            </template>
            <el-dialog v-model="innerVisible" :label="$t('switchGoogle.name6')" append-to-body width="400">
                <div class="innerVisible">
                    <div class="innerVisible_tit">{{$t('switchGoogle.text2')}}</div>
                    <div class="innerVisible_single">
                        <span>{{$t('switchGoogle.name7')}}</span>
                        <span>{{props.names}}</span>
                    </div>
                    <div class="innerVisible_single">
                        <span>{{$t('switchGoogle.name8')}}</span>
                        <span>{{showParms.name}}</span>
                    </div>
                    <div class="innerVisible_single">
                        <span>Google Key：</span>
                        <span class="red">{{showParms.key}}</span>
                        <el-button class="btn" :data-clipboard-text="showParms.key" text type="primary" @click="clipboardFun">{{$t('switchGoogle.text3')}}</el-button>
                    </div>
                </div>
                <template #footer>
                    <div class="dialog-footer">
                        <el-button type="primary" @click="innerVisible = false">{{$t('button.enter')}}</el-button>
                    </div>
                </template>
            </el-dialog>
        </el-dialog>
    </div>
</template>
<script setup>
    import { ref,defineExpose,reactive,defineProps,defineEmits,getCurrentInstance } from 'vue'
    import api_ from "@/api/index"
    import { ElNotification,ElMessage } from 'element-plus'
    // import { encryptions } from '@/utils/util'
    import ClipboardJS from 'clipboard'
    const { $t } = getCurrentInstance().proxy;

    const outerVisible = ref(false)
    const formEl = ref(null)
    const form = reactive({
        newpassword1:'',
        password:'',
        newGoogleKey:'',
        code:''
    })
    const props = defineProps({
        names:String,
        access_token:String
    })
    const showParms = reactive({
        name:'',
        key:''
    })
    const emit = defineEmits(['updatePassword'])
    const innerVisible = ref(false) 


    var clipboard = new ClipboardJS('.btn');
    clipboard.on('success', function() {
        ElMessage({
            message: $t('switchGoogle.text4'),
            type: 'success',
        })
    });
    const rules = reactive({
        newpassword1: [
            { required: true, message: $t('inputTit.shuru'), trigger: 'blur' }
        ],
        password: [
            { required: true, message: $t('inputTit.shuru'), trigger: 'blur' }
        ],
        newGoogleKey: [
            { required: true, message: $t('inputTit.text5'), trigger: 'blur' }
        ]
    })
    const getKey = ()=>{//获得key
        api_.getKeyByGoogle(props.names,props.access_token).then(res=>{
            if(res.code === 0){
                form.newGoogleKey = res.data.googleKey;
                showParms.name = res.data.accountName;
                showParms.key = res.data.googleKey;
                innerVisible.value = true
            }
        })   
    } 
    const enterFun = ()=>{//点击提交
        formEl.value.validate((formEl)=>{
            if (!formEl) return
            let obj_ = {
                newpassword1: form.newpassword1,
                password: form.password,
                newGoogleKey: form.newGoogleKey,
                code: form.code,
                username: props.names 
            }
            api_.updateInfoPassword(obj_,props.access_token,).then(res=>{
                if(res.code === 0){
                    emit('updatePassword')
                    ElNotification({
                        message: $t('alert.succUpdate'),
                        type: 'success',
                    })
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })
        })
    }
    const init_ = ()=>{
        outerVisible.value = true
    }
    defineExpose({
        init_
    })
</script>

<style lang='scss' scoped>
    .innerVisible{
        &_tit{
            font-size: 16px;
            margin-bottom: 5px;
            color: #999;
        }
        &_single{
            display: flex;align-content: center;
            span{
                line-height: 32px;
                height: 32px;
            }
            .red{
                color: red;
            }
        }
    }
</style>